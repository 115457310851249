@import "../../App";

.work {
  padding: 50px 0;
  .title {
    .line_title {
      font-size: 24px;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 15px;
      color: $white;

      &::before {
        content: "";
        width: 37px;
        border: 2px solid $white;
        display: inline-block;
        border-radius: 50px;
      }
    }

    h1 {
      color: #fff;
      font-weight: 600;
      font-size: 46px;
      width: 100%;
      line-height: 84px;
      margin-bottom: 20px;

      span {
        font-size: 46px;
        font-weight: 700;
        background: $themeTextGradint;
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
      }
    }

    p {
      color: $secondaryText;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
    }

    .dot {
      margin-top: 45px;
    }
  }
}
.work-head h2 {
  margin: 0;
  font-weight: 900;
  font-size: 68px;
  line-height: 90px;
  color: #1f1f1f;
  text-transform: capitalize;
  position: relative;
  z-index: 1;
}
.work-head p {
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #0f0f0f;
}
.work-head h2::before {
  content: "";
  position: absolute;
  bottom: 0;
  background-color: #8bc55e;
  height: 4px;
  width: 74px;
  left: 48%;
  bottom: -15%;
}
.work-head h2::after {
  content: "Work";
  position: absolute;
  white-space: nowrap;
  width: 100%;
  top: -25px;
  left: 0;
  font-weight: 900;
  font-size: 153px;
  line-height: 90px;
  color: rgba(171, 171, 171, 0.1);
  text-transform: uppercase;
  z-index: -1;
}

.portfolio {
  background-color: #202020;
  padding: 50px 0;
  .title {
    .line_title {
      font-size: 24px;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 15px;
      color: $white;

      &::before {
        content: "";
        width: 37px;
        border: 2px solid $white;
        display: inline-block;
        border-radius: 50px;
      }
    }

    h1 {
      color: #fff;
      font-weight: 600;
      font-size: 46px;
      width: 100%;
      line-height: 84px;
      margin-bottom: 20px;

      span {
        font-size: 46px;
        font-weight: 700;
        background: $themeTextGradint;
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
      }
    }

    p {
      color: $secondaryText;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
    }

    .dot {
      margin-top: 45px;
    }
  }
}
.portfolio-head h2 {
  margin: 0;
  font-weight: 900;
  font-size: 68px;
  line-height: 90px;
  color: #1f1f1f;
  text-transform: capitalize;
  position: relative;
  z-index: 1;
}
.portfolio-head p {
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #0f0f0f;
}
.portfolio-head h2::before {
  content: "";
  position: absolute;
  bottom: 0;
  background-color: #8bc55e;
  height: 4px;
  width: 74px;
  left: 45%;
  /* bottom: -15%; */
}
.portfolio-head h2::after {
  content: "Portfolio";
  position: absolute;
  white-space: nowrap;
  width: 100%;
  top: -25px;
  left: 0;
  font-weight: 900;
  font-size: 153px;
  line-height: 90px;
  color: rgba(171, 171, 171, 0.1);
  text-transform: uppercase;
  z-index: -1;
}

.portfolio_row {
  display: flex;
  width: 100%;
  padding: 0;
  flex-wrap: wrap;
  gap: 1rem;
}
.portfolio_filter_button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 15px 0;
  flex-wrap: wrap;
  button {
    margin-bottom: 12px;
    border: 2px solid #d2d2d2;
    color: gray;
    border-radius: 50px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 5px 25px;
    background: #faf9f9;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    font-family: Nunito Sans, sans-serif;
  }
  :hover,
  .active {
    color: #fff;
    border: 2px solid #c52319;
    background-color: #c52319;
  }
}

// UiUx Section

.uiux {
  margin-top: 50px;
  margin-bottom: 100px;
  position: relative;
  .react-multi-carousel-list {
    overflow-x: clip !important;
    overflow: unset;
    .react-multi-carousel-dot-list {
      bottom: -40px;
    }
    .react-multi-carousel-dot {
      button {
        border: 0 solid !important;
        background-color: #dfdfdf;
      }
    }
    .react-multi-carousel-dot--active {
      button {
        background-color: rgb(65, 65, 65);
      }
    }
  }
}

// Uiux Component

.uiux_box_wrapper {
  height: 500px;
  border-radius: 5px;
  margin: 0 12px;
  text-decoration: none;
  color: #fff;
  display: block;
  &.gredient1 {
    background-color: #0093e9 !important;
    background-image: linear-gradient(
      160deg,
      #0093e9 0%,
      #80d0c7 100%
    ) !important;
  }
  &.gredient2 {
    background-color: #4158d0 !important;
    background-image: linear-gradient(
      43deg,
      #4158d0 0%,
      #c850c0 46%,
      #ffcc70 100%
    ) !important;
  }
  &.gredient3 {
    background-color: #ff9a8b !important;
    background-image: linear-gradient(
      90deg,
      #ff9a8b 0%,
      #ff6a88 55%,
      #ff99ac 100%
    ) !important;
  }
  &.gredient4 {
    background-color: #8ec5fc !important;
    background-image: linear-gradient(
      62deg,
      #8ec5fc 0%,
      #e0c3fc 100%
    ) !important;
  }
  &.gredient5 {
    background-image: radial-gradient(
      circle farthest-corner at 10% 20%,
      rgba(171, 102, 255, 1) 0%,
      rgba(116, 182, 247, 1) 90%
    ) !important;
  }
  &.gredient6 {
    background-image: radial-gradient(
      circle farthest-corner at 10% 20%,
      rgba(255, 209, 67, 1) 0%,
      rgba(255, 145, 83, 1) 90%
    ) !important;
  }
  &.gredient7 {
    background-image: linear-gradient(
      109.6deg,
      rgba(218, 185, 252, 1) 11.2%,
      rgba(125, 89, 252, 1) 91.1%
    ) !important;
  }
  &.gredient8 {
    background: linear-gradient(25deg, #d64c7f, #ee4758 50%) !important;
  }
  &:hover {
    text-decoration: none;
    color: #fff;
  }
  .uiux_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    overflow: hidden;

    .content {
      width: 50%;
      height: 500px;
      padding: 50px 0px 50px 50px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      .title {
        font-size: 55px;
        font-weight: 900;
        margin: 0;
        line-height: 55px;
        margin-bottom: 50px;
        letter-spacing: 1px;
        text-transform: capitalize;
      }
      .desc {
        font-size: 22px;
        line-height: 28px;
      }
    }
    .image {
      width: 50%;
      height: 500px;
      padding: 30px 30px 0 10px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      img {
        width: 100%;
      }
    }
  }
}

.portfolio_title {
  p {
    text-transform: uppercase;
    color: #fff;
    font-weight: 700;
    font-size: 80px;
  }
}

// Responsive

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .portfolio {
    padding-top: 50px;
    .common_heading h1 {
      flex-direction: column;
      font-weight: 700;
      span {
        white-space: normal;
      }
    }
    &:after {
      font-size: 60px;
    }
  }
  .portfolio_wapper {
    width: 100% !important;
    height: 250px !important;
  }
  .portfolio_wapper:nth-child(3) {
    width: 100% !important;
    height: 250px !important;
  }
  .portfolio_wapper:nth-child(6) {
    width: 100% !important;
    height: 250px !important;
  }
  .portfolio_wapper:nth-child(11) {
    width: 100% !important;
    height: 250px !important;
  }

  .portfolio_title p {
    font-size: 40px !important;
    text-align: left !important;
  }

  // Uiux Component
  .uiux {
    margin-bottom: 50px;
  }
  .uiux_box_wrapper {
    height: 300px;
    .uiux_box {
      flex-direction: column;
      .content {
        width: 100%;
        height: 110px;
        padding: 15px;
        .title {
          font-size: 18px;
          line-height: 20px;
          margin-bottom: 10px;
        }
        .desc {
          font-size: 12px;
          line-height: 14px;
          letter-spacing: 0.3px;
        }
      }
      .image {
        width: 100%;
        height: 190px;
        padding: 15px 15px 0;
        img {
          width: 85%;
        }
      }
    }
  }
}
@media only screen and (min-width: 321px) and (max-width: 540px) {
  .portfolio {
    padding-top: 50px;
    .common_heading h1 {
      flex-direction: column;
      font-weight: 700;
      span {
        white-space: normal;
      }
    }
    &:after {
      font-size: 60px;
    }
  }
  .portfolio_wapper {
    width: 100% !important;
    height: 350px !important;
  }
  .portfolio_wapper:nth-child(3) {
    width: 100% !important;
    height: 350px !important;
  }
  .portfolio_wapper:nth-child(6) {
    width: 100% !important;
    height: 350px !important;
  }
  .portfolio_wapper:nth-child(11) {
    width: 100% !important;
    height: 350px !important;
  }
  .portfolio_title p {
    font-size: 55px;
    text-align: left !important;
  }

  // Uiux Component
  .uiux {
    margin-bottom: 50px;
  }
  .uiux_box_wrapper {
    height: 200px;
    .uiux_box {
      .content {
        width: 50%;
        height: 200px;
        padding: 15px;
        .title {
          font-size: 18px;
          line-height: 20px;
          margin-bottom: 10px;
          font-weight: 500;
        }
        .desc {
          font-size: 12px;
          line-height: 14px;
          letter-spacing: 0.3px;
        }
      }
      .image {
        width: 50%;
        height: 200px;
        padding: 15px 15px 0;
        img {
          width: 100%;
        }
      }
    }
  }
}
@media screen and (min-width: 541px) and (max-width: 767px) {
  .portfolio_wapper {
    width: 50% !important;
    height: 300px !important;
  }
  .portfolio_wapper:nth-child(3) {
    width: 100% !important;
    height: 650px !important;
  }
  .portfolio_wapper:nth-child(6) {
    width: 100% !important;
    height: 650px !important;
  }
  .portfolio_wapper:nth-child(11) {
    width: 100% !important;
    height: 650px !important;
  }
  .portfolio_title p {
    font-size: 60px;
    text-align: left !important;
  }
  // Uiux Component
  .uiux {
    margin-bottom: 50px;
  }
  .uiux_box_wrapper {
    height: 200px;
    .uiux_box {
      .content {
        width: 50%;
        height: 200px;
        padding: 15px;
        .title {
          font-size: 18px;
          line-height: 20px;
          margin-bottom: 10px;
          font-weight: 500;
        }
        .desc {
          font-size: 12px;
          line-height: 14px;
          letter-spacing: 0.3px;
        }
      }
      .image {
        width: 50%;
        height: 200px;
        padding: 15px 15px 0;
        img {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio_wapper {
    width: 50% !important;
    height: 300px !important;
  }
  .portfolio_wapper:nth-child(3) {
    width: 100% !important;
    height: 650px !important;
  }
  .portfolio_wapper:nth-child(6) {
    width: 100% !important;
    height: 650px !important;
  }
  .portfolio_wapper:nth-child(11) {
    width: 100% !important;
    height: 650px !important;
  }
  .portfolio_title p {
    font-size: 60px;
    text-align: left !important;
  }
  // Uiux Component
  .uiux {
    margin-bottom: 50px;
  }
  .uiux_box_wrapper {
    height: 200px;
    .uiux_box {
      .content {
        width: 50%;
        height: 200px;
        padding: 15px;
        .title {
          font-size: 18px;
          line-height: 20px;
          margin-bottom: 10px;
          font-weight: 500;
        }
        .desc {
          font-size: 12px;
          line-height: 14px;
          letter-spacing: 0.3px;
        }
      }
      .image {
        width: 50%;
        height: 200px;
        padding: 15px 15px 0;
        img {
          width: 100%;
        }
      }
    }
  }
  .work {
    padding-top: 110px;
  }
  .work-head h2 {
    font-size: 50px;
  }
  .work-head h2::after {
    top: -25px;
    font-size: 74px;
  }
  .portfolio {
    padding: 10px 0 30px;
  }
  .portfolio-head h2 {
    font-size: 50px;
  }
  .portfolio-head h2::after {
    top: -25px;
    font-size: 74px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1023px) {
  .portfolio_wapper {
    height: 230px !important;
  }
  .portfolio_wapper:nth-child(3) {
    height: 460px !important;
  }
  .portfolio_wapper:nth-child(6) {
    height: 460px !important;
  }
  .portfolio_wapper:nth-child(11) {
    height: 460px !important;
  }

  // Uiux Component
  .uiux {
    margin-bottom: 50px;
  }
  .uiux_box_wrapper {
    height: 200px;
    .uiux_box {
      .content {
        width: 50%;
        height: 200px;
        padding: 15px;
        .title {
          font-size: 18px;
          line-height: 20px;
          margin-bottom: 10px;
          font-weight: 500;
        }
        .desc {
          font-size: 12px;
          line-height: 14px;
          letter-spacing: 0.3px;
        }
      }
      .image {
        width: 50%;
        height: 200px;
        padding: 15px 15px 0;
        img {
          width: 100%;
        }
      }
    }
  }
}
@media screen and (min-width: 1024px) and (max-width: 1280px) {
  .portfolio_wapper {
    height: 230px !important;
  }
  .portfolio_wapper:nth-child(3) {
    height: 460px !important;
  }
  .portfolio_wapper:nth-child(6) {
    height: 460px !important;
  }
  .portfolio_wapper:nth-child(11) {
    height: 460px !important;
  }
  // Uiux Component
  .uiux {
    margin-bottom: 50px;
  }
  .uiux_box_wrapper {
    height: 250px;
    .uiux_box {
      .content {
        width: 50%;
        height: 250px;
        padding: 15px;
        .title {
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 10px;
          font-weight: 500;
        }
        .desc {
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.5px;
          letter-spacing: 0.3px;
        }
      }
      .image {
        width: 50%;
        height: 250px;
        padding: 15px 15px 0;
        img {
          width: 100%;
        }
      }
    }
  }
}
@media screen and (min-width: 1281px) and (max-width: 1480px) {
  // Uiux Component
  .uiux {
    margin-bottom: 50px;
  }
  .uiux_box_wrapper {
    height: 250px;
    .uiux_box {
      .content {
        width: 50%;
        height: 250px;
        padding: 15px;
        .title {
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 10px;
          font-weight: 500;
        }
        .desc {
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.5px;
          letter-spacing: 0.3px;
        }
      }
      .image {
        width: 50%;
        height: 250px;
        padding: 15px 15px 0;
        img {
          width: 100%;
        }
      }
    }
  }
}
@media screen and (min-width: 992px) and (max-width: 1024px) {
  .work-head h2::after {
    top: -25px;
    font-size: 74px;
  }
  .portfolio-head h2::after {
    top: -25px;
    font-size: 74px;
  }
  .work {
    padding-top: 110px;
  }
}
@media only screen and (max-width: 600px) {
  .work-head h2 {
    font-size: 42px;
    line-height: 50px;
  }
  .work-head h2::before {
    bottom: -5px;
    left: 37%;
  }
  .work-head h2::after {
    font-size: inherit;
    line-height: 80px;
    top: -35px;
  }
  .work-head p {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    margin-top: 15px !important;
    margin-bottom: 0 !important;
    width: 100%;
  }
  .work {
    padding: 60px 0px 20px;
  }

  .portfolio-head h2 {
    font-size: 42px;
    line-height: 50px;
  }
  .portfolio-head h2::before {
    bottom: -5px;
    left: 37%;
  }
  .portfolio-head h2::after {
    font-size: inherit;
    line-height: 80px;
    top: -35px;
  }
  .portfolio-head p {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    margin-top: 15px !important;
    margin-bottom: 0 !important;
    width: 100%;
  }
  .portfolio {
    padding: 60px 0px 20px;
  }
  .common_heading.text-center.mt-5.portfolio-head {
    margin-top: 0 !important;
  }
  .portfolio_filter_button button {
    font-size: 12px;
    padding: 5px 10px;
  }
}
