.service_uiuxDesign {
  padding: 45px 0;
  .leftSide {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    position: relative;
    .titleName {
      display: flex;
      gap: 30px;
      .countNumber {
        font-size: 128px;
        font-weight: 700;
        color: #202020;
      }
      p {
        font-size: 46px;
        font-weight: 600;
        color: #fff;
        display: flex;
        flex-direction: column;
        margin: 0;
        justify-content: center;

        span {
          background: linear-gradient(
            92.05deg,
            #dc2418 12.4%,
            #841878 49.47%,
            #ba0c0c 85%
          );
          -webkit-text-fill-color: transparent;
          -webkit-background-clip: text;
        }
      }
    }
    .uiux_vector {
      position: absolute;
      bottom: 0px;
      right: 9rem;
    }
    .theme_btn {
      width: 199px;
      height: 55px;
    }
  }
  .rightSide {
    p {
      font-size: 18px;
      font-weight: 400;
      color: #cbcbcb;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem 0;
      padding-left: 20px;
      li {
        width: 50%;
        color: #cbcbcb;
        font-size: 18px;
        font-weight: 400;
      }
    }
  }
  .banner {
    margin-top: 5rem;
    display: flex;
    align-items: center;
    gap: 2rem;
    img {
      width: 100%;
    }
    .uiux_banner1 {
      top: -2rem;
      position: relative;
    }
  }
}
.service_appDevelopment {
  padding: 45px 0;
  .leftSide {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    position: relative;
    .titleName {
      display: flex;
      gap: 30px;
      .countNumber {
        font-size: 128px;
        font-weight: 700;
        color: #202020;
      }
      p {
        font-size: 46px;
        font-weight: 600;
        color: #fff;
        display: flex;
        flex-direction: column;
        margin: 0;
        justify-content: center;

        span {
          background: linear-gradient(
            92.05deg,
            #dc2418 12.4%,
            #841878 49.47%,
            #ba0c0c 85%
          );
          -webkit-text-fill-color: transparent;
          -webkit-background-clip: text;
        }
      }
    }
    .uiux_vector {
      position: absolute;
      bottom: 0px;
      right: 14rem;
    }
    .theme_btn {
      width: 199px;
      height: 55px;
      margin-left: auto;
    }
  }
  .rightSide {
    p {
      font-size: 18px;
      font-weight: 400;
      color: #cbcbcb;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem 0;
      padding-left: 20px;
      li {
        width: 50%;
        color: #cbcbcb;
        font-size: 18px;
        font-weight: 400;
      }
    }
  }
  .banner {
    margin-top: 5rem;
    display: flex;
    align-items: center;
    gap: 2rem;
    img {
      width: 100%;
    }
    .uiux_banner1 {
      top: -2rem;
      position: relative;
    }
  }
}
