@import "../App";

.not_found{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img{
        max-width: 600px;
        width: 100%;
    }
    .error_text{
        font-size: 60px;
        font-weight: 700;
        color: #fff;
        padding: 20px 0;
    }
    button{
        border: 2px solid #fff;
        background: transparent;
        color: #fff;
        padding: 12px 50px;
        border-radius: 60px;
        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;
        transition: 0.3s;
    }
    button:hover{
        border: 2px solid #8dc85e;
        color: #8dc85e;
    }
}