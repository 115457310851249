// Career

@import "../../App";

.career {
  padding: 50px 0;

  .wwd_heading {
    padding: 80px 0 30px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1 {
      font-weight: 900;
      font-size: 68px;
      line-height: 70px;
      color: $black;
      text-transform: capitalize;
      position: relative;
      z-index: 1;
      text-align: center;
      width: 100%;

      &::after {
        content: "Join Team";
        white-space: nowrap;
        position: absolute;
        width: max-content;
        top: -50px;
        left: 50%;
        transform: translateX(-50%);
        font-weight: 900;
        font-size: 153px;
        line-height: 90px;
        color: rgba(171, 171, 171, 0.1);
        text-transform: uppercase;
        z-index: -1;
      }

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        background-color: $maingreen;
        height: 4px;
        width: 74px;
      }

      span {
        color: $maingreen;
      }
    }

    .wwd_tagline {
      font-weight: 700;
      font-size: 22px;
      line-height: 30px;
      color: #0f0f0f;
    }
  }

  .title {
    .line_title {
      font-size: 24px;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 15px;
      color: $white;

      &::before {
        content: "";
        width: 37px;
        border: 2px solid $white;
        display: inline-block;
        border-radius: 50px;
      }
    }

    h1 {
      color: #fff;
      font-weight: 600;
      font-size: 46px;
      width: 100%;
      line-height: 84px;
      margin-bottom: 20px;

      span {
        font-size: 46px;
        font-weight: 700;
        background: $themeTextGradint;
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
      }
    }

    p {
      color: $secondaryText;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
    }

    .dot {
      margin-top: 45px;
    }
  }

  .career_box {
    width: 100%;
    height: 360px;
    position: relative;
    overflow: hidden;
    background-color: #202020;
    padding: 20px;
    margin-top: 25px;
    border-radius: 10px;
    transition: 0.5s;

    img {
      width: 40px;
      height: 40px;
    }

    .title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      // font-size: 14px;
      font-weight: 600;
      color: #fff;
      margin-bottom: 0.5rem;
      letter-spacing: 1px;
      width: 100%;

      box-icon {
        width: 27px;
        height: 27px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 8px;
      }
    }

    h2 {
      font-size: 20px;
      line-height: 27px;
      font-weight: 600;
      color: #fff;
      padding-bottom: 10px;
      border-bottom: 2px dashed #939393;
      margin-bottom: 1.5rem;

      .img_wrapper {
        height: 50px;
        width: 50px;
        border-radius: 5px;
        padding: 5px;
        margin-bottom: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgb(255, 255, 255);
        margin-right: 15px;

        img {
          margin-bottom: 0px;
          width: 100%;
          padding: 0;
        }
      }
    }

    h5 {
      letter-spacing: 1px;
      color: #cbcbcb;
      line-height: 20px;
      font-size: 14px;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0;
    }

    p {
      color: #cbcbcb;
      font-size: 14px;
      letter-spacing: 1px;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    ul {
      margin: 0;
      padding-left: 0px;

      ul {
        padding-left: 10px;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        h4 {
          color: #cbcbcb;
          font-size: 14px;
        }
      }
    }

    li {
      list-style: circle;
      // margin-bottom: 10px;
      margin-left: 8px;
      letter-spacing: 1px;
      color: #cbcbcb;
      font-size: 14px;
      // font-weight: 600;
    }

    button,
    a {
      border: 0;
      font-size: 17px;
      border-radius: 50px;
      transition: 0.3s;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      position: absolute;
      bottom: 20px;
      right: 20px;
      background-color: transparent;
      color: #fff;

      .icon {
        font-size: 34px;
        margin-left: 0.5rem;
      }
    }

    button:hover,
    button.active {
      color: $themeRed;
    }
  }
}

.viewmore_data {
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
}

.view_more_active {
  height: calc(100% - 10px) !important;

  .viewmore_data {
    opacity: 1;
    visibility: visible;
  }

  h5 {
    -webkit-line-clamp: unset !important;
  }
}

.apply_now {
  background: transparent;
  z-index: 4;
  max-width: 1200px;
  height: max-content;
  padding: 50px;
  position: relative;

  button {
    border: 1px solid $black;
    color: $black;
    border-radius: 5px;
    font-size: 16px;
    letter-spacing: 2.5px;
    text-transform: uppercase;
    font-weight: 800;
    padding: 12px 50px;
    // background: transparent;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    user-select: none;
    align-items: center;
    margin-top: 30px;

    &:disabled {
      pointer-events: none;
      color: gray;
      border: 1px solid gray;
    }
  }

  button:hover,
  button.active {
    color: $maingreen;
    border: 1px solid $maingreen;
  }

  // select:hover,
  // input:hover {
  //   color: $maingreen;
  //   border-color: $maingreen;
  // }
  select,
  input {
    margin-top: 30px;
    color: #fff;
    border: 1px solid #3f3f3f;
    padding: 10px 15px;
    border-radius: 6px;
    border-left: 5px solid #3f3f3f;
    outline: none;
    background-color: #202020;
    width: 100%;

    option {
      background-color: #202020;
    }
  }

  select {
    appearance: none;
    background-image: url("../../Assets/Images/drop_down.svg");
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: calc(100% - 16px);
  }

  select:invalid,
  input::placeholder {
    color: #fff !important;
    text-transform: capitalize;
    letter-spacing: 1.5px !important;
  }

  .error_msg {
    color: #ff4a4a;
    font-size: 16px;
    font-weight: 400;
  }
}

.apply_now_back {
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000fc;
  z-index: 9999;
  opacity: 100%;
  position: fixed;
  height: 100vh;
  width: 0%;
  transition: 0.5s;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  .close {
    background-color: transparent;
    height: 100px;
    width: 100px;
    border: 0px;
    font-size: 80px;
    font-weight: 100;
    transform: rotate(45deg);
    position: absolute;
    color: #fff;
    right: 0;
    top: 0;
    z-index: 5;
    outline: none;
  }
}

.apply_now_back.active {
  height: 100vh;
  width: 100%;
}

@media screen and (min-width: 992px) and (max-width: 1024px) {
  .career .wwd_heading h1::after {
    top: -25px;
    font-size: 84px;
  }

  .career {
    padding: 110px 0 70px 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .career .wwd_heading h1 {
    font-size: 50px;
  }

  .career .wwd_heading h1::after {
    top: -25px;
    font-size: 84px;
  }

  .career .wwd_heading {
    padding: 0px 0 0px 0;
  }
}

@media only screen and (max-width: 600px) {
  .career .wwd_heading h1 {
    font-size: 42px;
    line-height: 50px;
  }

  .career .wwd_heading h1::before {
    bottom: -5px;
    left: 37%;
  }

  .career .wwd_heading h1::after {
    font-size: inherit;
    line-height: 80px;
    top: -35px;
  }

  .career .wwd_heading p {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    margin-top: 15px !important;
    margin-bottom: 0 !important;
    width: 100%;
  }

  .career .wwd_heading {
    padding: 30px 0 30px 0;
  }

  .career {
    padding: 70px 0px 20px !important;
  }

  .career .wwd_heading .wwd_tagline {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    margin-top: 15px !important;
    margin-bottom: 0 !important;
  }

  .career .career_box {
    margin-bottom: 20px;
    height: auto;
  }

  .apply_now_back .close {
    top: 55px;
    right: 13px;
    width: auto;
    height: auto;
    font-size: 60px;
  }

  .viewmore_data {
    opacity: 1;
    visibility: visible;
    transition: 0.5s;
    padding-left: 8px;
  }
}