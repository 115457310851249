@import "../../../App";

.technologiesNew {
    position: relative;
    overflow: hidden;
    padding: 3rem 0;

    .title {
        margin-bottom: 4rem;

        .line_title {
            font-size: 24px;
            font-weight: 600;
            display: flex;
            align-items: center;
            gap: 15px;
            color: $white;

            &::before {
                content: "";
                width: 37px;
                border: 2px solid $white;
                display: inline-block;
                border-radius: 50px;
            }
        }

        h1 {
            color: #fff;
            font-weight: 600;
            font-size: 46px;
            width: 100%;
            line-height: 84px;
            margin-bottom: 20px;

            span {
                font-size: 46px;
                font-weight: 700;
                background: $themeTextGradint;
                -webkit-text-fill-color: transparent;
                -webkit-background-clip: text;
            }
        }

        p {
            color: $secondaryText;
            font-size: 18px;
            font-weight: 400;
            line-height: 27px;
        }
    }

    .technologiesDiv {
        .technologies_filter_button {
            margin-bottom: 55px;
            text-align: center;

            button {
                border: 0;
                background-color: transparent;
                padding: 7px 10px;
                color: $secondaryText;
                font-size: 16px;
                font-weight: 400;
                min-width: 134px;
                border-bottom: 1px solid gainsboro;

                &.active {
                    background: linear-gradient(90.03deg, #dc2418 0.75%, #861773 99.98%);
                    -webkit-text-fill-color: transparent;
                    -webkit-background-clip: text;
                    border-color: #740000;
                    font-weight: 600;
                }
            }
        }
    }

    .technologies_row {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 40px;
    }

    .dot {
        position: absolute;
        bottom: 0;
        left: 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .technologiesNew {
        padding: 1rem 0;

        .dot {
            display: none;
        }

        .title {
            margin-bottom: 2rem;

            h1 {
                font-size: 26px;
                line-height: normal;

                span {
                    font-size: 26px;
                }
            }
        }

        .technologiesDiv {
            .technologies_filter_button {
                margin-bottom: 2rem;
            }

            .technologies_row {
                gap: 1rem;

                .portfolio_wapper_new {
                    width: 30%;
                    height: auto;

                    .outbox {
                        transform: rotateY(0);

                        .innerbox {
                            padding: 1rem 0.5rem;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .technologiesNew {
        padding: 1rem 0;

        .dot {
            display: none;
        }

        .title {
            margin-bottom: 2rem;

            h1 {
                font-size: 26px;
                line-height: normal;

                span {
                    font-size: 26px;
                }
            }
        }

        .technologiesDiv {
            .technologies_filter_button {
                margin-bottom: 2rem;

                button {
                    min-width: max-content;
                }
            }

            .technologies_row {
                gap: 1rem;

                .portfolio_wapper_new {
                    width: 30%;
                    height: auto;

                    .outbox {
                        transform: rotateY(0);

                        .innerbox {
                            padding: 1rem 0.5rem;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .technologiesNew {
        padding: 1rem 0;

        .dot {
            display: none;
        }

        .title {
            margin-bottom: 2rem;

            h1 {
                font-size: 26px;
                line-height: normal;

                span {
                    font-size: 26px;
                }
            }
        }

        .technologiesDiv {
            .technologies_filter_button {
                margin-bottom: 2rem;

                button {
                    min-width: max-content;
                }
            }

            .technologies_row {
                gap: 1rem;

                .portfolio_wapper_new {
                    width: 45%;
                    height: auto;

                    .outbox {
                        transform: rotateY(0);

                        .innerbox {
                            padding: 1rem 0.5rem;
                        }
                    }
                }
            }
        }
    }
}