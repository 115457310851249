@import "../App";
.portfolio_wapper_new {
  box-sizing: border-box;
  width: 14%;
  height: 157px;
  perspective: 500px;
  .outbox {
    background: linear-gradient(
      128.22deg,
      #dc2418 0%,
      #4e1848 46.74%,
      #740000 111.82%
    );
    border-radius: 10px;
    padding: 1px;
    overflow: hidden;
    width: 100%;
    margin: auto;
    transition: 0.5s;
    &.left {
      transform: rotateY(15deg);
    }
    &.right {
      transform: rotateY(-15deg);
    }
    &:hover {
      transform: rotateY(0deg);
    }
    .innerbox {
      width: 100%;
      background-color: #111111;
      border-radius: 10px;
      height: 100%;
      padding: 30px 20px 20px;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 30px;
      svg, img{
        width: 40px;
        height: 40px;
        color: #fff;
      }
      p {
        margin: 0;
        color: $white;
      }
    }
  }
}
// .portfolio_wapper_new:nth-child(3) {
//   width: 50%;
//   height: 560px;
//   float: right;
// }
// .portfolio_wapper_new:nth-child(6) {
//   width: 50%;
//   height: 560px;
//   float: left;
// }
// .portfolio_wapper_new:nth-child(11) {
//   width: 50%;
//   height: 560px;
//   float: right;
// }
// .portfolio_wapper_new:nth-child(16) {
//   width: 50%;
//   height: 560px;
//   float: left;
// }
// .portfolio_wapper_new:nth-child(21) {
//   width: 50%;
//   height: 560px;
//   float: right;
// }
// .portfolio_wapper_new:nth-child(26) {
//   width: 50%;
//   height: 560px;
//   float: left;
// }
// .portfolio_wapper_new:nth-child(31) {
//   width: 50%;
//   height: 560px;
//   float: right;
// }
// .portfolio_wapper_new:nth-child(36) {
//   width: 50%;
//   height: 560px;
//   float: left;
// }
.portfolio_box {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: $black;
  border-radius: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .project_details {
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.63);
    height: 100%;
    width: 100%;
    transform: translatex(-100%);
    transition: 0.5s;
    padding: 0 30px;
    text-align: center;

    h2 {
      color: #fff;
      transform: translatex(-200%);
      transition: 0.3s;
      transition-delay: 0s;
      transition-delay: 0.3s;
      font-size: 28px;
      font-weight: 900;
      text-transform: uppercase;
    }
    h4 {
      color: #fff;
      transform: translatex(-500%);
      transition: 0.3s;
      transition-delay: 0s;
      transition-delay: 0.4s;
      font-size: 20px;
      font-weight: 600;
    }
    a {
      border: 3px solid #fff;
      color: #1f1f1f;
      border-radius: 50px;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      font-weight: 800;
      padding: 5px 25px;
      background: #fff;
      background-color: rgb(255, 255, 255);
      transition: 0.3s;
      transition-delay: 0s;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      text-decoration: none;
      margin-top: 10px;
      transform: translatey(100%);
      opacity: 0;
      transition-delay: 0.5s;
      background-color: #fff;
      font-family: Nunito Sans, sans-serif;
    }
    a:hover,
    a.active {
      transition: 0.3s;
      color: #fff;
      border: 3px solid #8bc55e;
      background: #8bc55e;
    }
  }
}
.portfolio_box:hover .project_details {
  transform: translatex(0%);
  h2 {
    transform: translatex(0%);
  }
  h4 {
    transform: translatex(0%);
  }
  a {
    transform: translatey(0%);
    opacity: 1;
  }
}
