@import "./../../App.scss";

// .blog_img {
//   height: 340px;
// }
p.line-hight-2 {
  line-height: 31px;
}

.blogitem {
  // background-color: $white;
  // border-radius: 10px;
  padding: 0px;
  overflow: hidden;
  // box-shadow: 0px 9px 20px #0000001f;
  margin-bottom: 20px;

  .blog_img {
    border-radius: 10px;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
    }
  }

  .blog_content {
    padding: 40px 0 20px;
    position: relative;
    color: #fff;

    .blog_info {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1254901961);
      position: absolute;
      top: -20px;
      left: 20px;

      span {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 0;
        padding: 10px 20px;
        background-color: #202020;
        border-radius: 10px;
        border: 1px solid #5e1e1a;
      }
    }

    h3 {
      font-size: 20px;
      font-weight: 700;
      display: -webkit-box;
      max-width: 469px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-bottom: 12px;
    }

    p {
      // color: $black;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 0;
      display: -webkit-box;
      max-width: 469px;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      opacity: 0.7;
      margin-top: 10px;
    }

    a {
      font-size: 14px;
      transition: 0.3s;
      margin-top: 1rem;
      color: #dc2418;
      display: flex;
      align-items: center;
      gap: 1rem;
      justify-content: space-between;

      .date {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }

      .readmore {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }

      span {
        color: #fff;
      }
    }

    // .common_more {
    //   padding-top: 10px;
    // }
  }
}

.blog_page {
  padding: 50px 0;
  background: var(--black-2, #202020);

  .title {
    .line_title {
      font-size: 24px;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 15px;
      color: $white;

      &::before {
        content: "";
        width: 37px;
        border: 2px solid $white;
        display: inline-block;
        border-radius: 50px;
      }
    }

    h1 {
      color: #fff;
      font-weight: 600;
      font-size: 46px;
      width: 100%;
      line-height: 84px;
      margin-bottom: 20px;

      span {
        font-size: 46px;
        font-weight: 700;
        background: $themeTextGradint;
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
      }
    }

    p {
      color: $secondaryText;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
    }

    .dot {
      margin-top: 45px;
    }
  }
}

@media screen and (max-width: 540px) {
  .blog_page {
    padding: 80px 0 0 0;
  }
}

// View Blog Page

.viewblog {
  padding: 50px 0;

  .common_heading {
    h1 {
      font-size: 40px;
      font-weight: 700;
      line-height: 50px;
      color: #fff;
    }
  }

  .viewblog_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    border-bottom: 1px solid #00000030;

    p {
      color: #fff;
      font-size: 18px;
      font-weight: 700;

      span {
        font-weight: 800;
      }
    }
  }

  .blogImage {
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  .points_wrapper {
    margin-top: 40px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: space-between;

    &.imagebox {
      .point_content {
        width: 50%;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }

      .points_image {
        width: 50%;
        flex: 1;
      }
    }

    &.reverse {
      flex-direction: row-reverse;

      .points_image {
        margin-left: 0;
        margin-right: 30px;
      }
    }

    .points_title {
      h2 {
        margin: 0 0 15px 0;
        color: #fff;
        font-weight: 800;
        line-height: 34px;
      }

      h3 {
        margin: 0 0 15px 0;
        color: #fff;
        font-weight: 800;
        line-height: 34px;
      }

      h4 {
        margin: 0 0 15px 0;
        color: #fff;
        font-weight: 800;
        line-height: 34px;
      }
    }

    .points_subtitle {
      h3 {
        margin: 0 0 15px 0;
        color: #fff;
        font-size: 20px;
        font-weight: 800;
      }
    }

    .points_description {
      p {
        margin: 0;
        margin-bottom: 15px;
        color: #cbcbcb;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0.8px;
        font-size: 18px;
        margin-bottom: 20px;

        a {
          color: #cbcbcb;
        }
      }
    }

    .steps_description {
      .step_desc {
        padding-left: 0px;
        font-style: normal;
        color: #cbcbcb;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;

        &::after {
          display: none;
        }
      }

      p {
        margin: 0;
        margin-bottom: 15px;
        color: #cbcbcb;
        font-weight: 600;
        letter-spacing: 0.8px;
        font-size: 16px;
        position: relative;
        padding-left: 20px;

        a {
          color: #cbcbcb;
        }

        &::after {
          content: "";
          height: 8px;
          width: 8px;
          border-radius: 10px;
          background-color: #dc2418;
          top: 7px;
          left: 0;
          position: absolute;
        }
      }
    }

    .points_image {
      margin-left: 30px;
      width: 100%;
      float: right;
      border-radius: 0px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  .FAQ {
    .faq_title {
      margin-top: 50px;
      margin-bottom: 30px;

      h2 {
        font-size: 42px;
        color: #fff;
        font-weight: 800;
      }
    }

    .faq_question {
      .faq_questions {
        h3 {
          color: #cbcbcb;
          font-size: 22px;
          font-weight: 700;
          letter-spacing: 0.5px;
          margin-top: 30px;
        }
      }

      .faq_answer {
        p {
          font-size: 16px;
          color: #cbcbcb;
          font-weight: 600;
          letter-spacing: 0.5px;
          padding-left: 25px;

          a {
            color: #cbcbcb;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .viewblog {
    padding: 150px 0 20px;
  }
}

@media screen and (max-width: 1024px) {
  .viewblog {
    padding: 100px 0 20px;

    .common_heading h1 {
      line-height: 55px;
    }
  }

  .points_wrapper {
    margin-top: 20px;
    flex-direction: column;

    &.reverse {
      flex-direction: column !important;
    }

    &.imagebox {
      .point_content {
        width: 100% !important;
      }

      .points_image {
        width: 100% !important;
        margin: 0;
        margin-bottom: 15px;
        margin-top: 10px;
      }
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1024px) {
  .blog_page .wwd_heading h2::after {
    top: -25px;
    font-size: 84px;
  }

  .blog_page {
    padding: 110px 0 0 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .viewblog .common_heading h1 {
    font-size: 33px;
    margin: 0;
  }

  .viewblog {
    padding: 110px 0 20px;
  }

  .blog_page .wwd_heading h2 {
    font-size: 50px;
  }

  .blog_page .wwd_heading h2::after {
    top: -25px;
    font-size: 84px;
  }

  .blog_page .wwd_heading {
    padding: 0px 0 30px 0;
  }
}

@media only screen and (max-width: 600px) {
  .blog_page .wwd_heading h2 {
    font-size: 42px;
    line-height: 50px;
  }

  .blog_page .wwd_heading h2::before {
    bottom: -5px;
    left: 37%;
  }

  .blog_page .wwd_heading h2::after {
    font-size: inherit;
    line-height: 80px;
    top: -35px;
  }

  .blog_page .wwd_heading p {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    margin-top: 15px !important;
    margin-bottom: 0 !important;
    width: 100%;
  }

  .blog_page {
    padding: 60px 0px 20px;
  }

  .blog_page .wwd_heading {
    padding: 60px 0 0px 0;
  }

  .blog_page .wwd_heading .wwd_tagline {
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 0px;
  }

  .viewblog .common_content p {
    margin: 10px 0 !important;
  }

  .viewblog .common_content.text-left.mt-5 {
    margin-top: 1rem !important;
  }

  .viewblog .points_wrapper .points_description p {
    font-size: 14px !important;
    line-height: 23px;
  }

  .viewblog .FAQ .faq_title {
    margin-top: 30px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 540px) {
  .blogitem .blog_content h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
  }

  .viewblog {
    padding: 70px 0 20px;

    .viewblog_header {
      p {
        font-size: 14px;
        line-height: 23px;
      }
    }

    .common_heading {
      h1 {
        font-size: 20px;
        line-height: 30px;
        font-weight: 400;
      }
    }

    .common_content {
      p {
        font-size: 12px !important;
        text-align: left;
      }
    }

    .points_wrapper {
      margin-top: 20px;
      flex-direction: column;

      &.reverse {
        flex-direction: column;
      }

      .points_title {
        h2 {
          font-size: 18px;
          line-height: 24px;
        }
      }

      .points_description {
        p {
          font-size: 12px;
        }
      }

      &.imagebox {
        .point_content {
          width: 100%;
        }

        .points_image {
          width: 100%;
          margin: 0;
          margin-bottom: 15px;
          margin-top: 10px;
        }
      }

      .steps_description {
        .step_desc {
          font-size: 14px;
        }

        p {
          font-size: 12px;
          margin-bottom: 10px;
        }
      }
    }

    .FAQ {
      .faq_title {
        h2 {
          font-size: 24px;
        }
      }

      .faq_question {
        .faq_questions {
          h3 {
            font-size: 16px;
            line-height: 22px;
          }
        }

        .faq_answer {
          p {
            font-size: 14px;
            padding: 0;
          }
        }
      }
    }
  }

  footer {
    margin-top: 0;
  }

  .blogitem {
    margin-top: 0px;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 360px) {
  .viewblog {
    .viewblog_header {
      padding: 10px 0;

      p {
        span {
          display: block;
        }
      }
    }
  }

  // .blogitem .blog_img img {
  //   height: auto;
  // }
}