@import "./App";
* {
  &::-webkit-scrollbar{
    width: 2px !important;
    height: 5px !important;
    background: #1f1f1f;
  }

  &::-webkit-scrollbar-thumb {
    background: #111111;
    border-radius: 2px;
    width: 2px !important;
    height: 5px !important;
  }
}
body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  // font-family: Nunito Sans, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $black !important;
  font-display: swap;
  overflow-x: hidden;
}
a{
  text-decoration: none;
  display: block;
}
