// @import "../Home/Custome.scss";
@import "../../App.scss";

.servicepage {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 5rem 0 0;
  display: flex;
  align-items: center;

  .left {
    width: 85%;
    margin-left: auto;

    h1 {
      color: #fff;
      font-weight: 700;
      font-size: 56px;
      width: 100%;
      text-align: left;
      line-height: 84px;
      margin-bottom: 0px;
      position: relative;

      &::after {
        content: "";
        width: 1px;
        height: 100%;
        position: absolute;
        top: 0;
        left: -35px;
        background-color: #c9c9c9;
      }

      span {
        background: $themeTextGradint;
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
      }

      .sideSubTxt {
        position: absolute;
        transform: translate(-50%, -50%) rotate(-90deg);
        top: 50%;
        left: -75px;
        font-size: 26px;
        text-align: center;
        line-height: normal;
      }
    }

    p {
      color: $secondaryText;
      font-weight: 400;
      font-size: 20px;
      line-height: 40px;
    }
  }

  .right {
    img {
      position: absolute;
      top: 0;
      right: -30px;
      height: 100%;
      width: inherit;
      border-radius: 0px 0px 0px 50px;
      object-fit: cover;
    }
  }

  .dot {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

.servicePageClient {
  padding: 45px 0;
  background: #202020;
  margin-top: 80px;

  .left {
    p {
      font-size: 24px;
      font-weight: 400;
      color: #fff;
      margin: 0;
    }
  }

  .right {
    display: flex;
    align-items: center;
    gap: 60px;
    justify-content: space-between;
  }

  .carousel_card {
    width: 100%;
    border-radius: 0px 40px 40px 0px;
    background: #202020;
    padding: 4rem 0rem;

    .inner {
      position: relative;
      width: 100%;
      overflow: hidden;

      .wrapper {
        display: flex;
        gap: 102px;
      }

      .slide {
        display: flex;
        animation: swipe var(--speed) linear infinite backwards;
        gap: 102px;
        flex: none;

        .image {
          flex: none;
          img {
            width: 100px;
            height: 100px;
          }
        }
      }

      @keyframes swipe {
        0% {
          transform: translate(0);
        }

        100% {
          transform: translate(-100%);
        }
      }
    }
  }
}

.scheduleCall {
  padding: 45px 0;

  .box {
    background-color: #202020;
    padding: 3rem 4rem;
    text-align: center;
    position: relative;

    .boxPettern {
      position: absolute;
      top: 0;
      left: 0;
    }

    h1 {
      color: #fff;
      font-size: 46px;
      font-weight: 700;
      margin-bottom: 24px;
      position: relative;

      span {
        background: $themeTextGradint;
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
      }
    }

    p {
      color: #cbcbcb;
      margin-bottom: 54px;
      position: relative;
      font-size: 18px;
    }

    .theme_btn {
      margin: auto;
      color: #fff;
      padding: 1rem 2.5rem;
      position: relative;
    }
  }
}

.service_aiArtificial {
  padding: 45px 0;

  .leftSide {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    position: relative;

    .titleName {
      display: flex;
      gap: 30px;

      .countNumber {
        font-size: 128px;
        font-weight: 700;
        color: #202020;
      }

      p {
        font-size: 46px;
        font-weight: 600;
        color: #fff;
        display: flex;
        flex-direction: column;
        margin: 0;
        justify-content: center;

        span {
          background: linear-gradient(92.05deg,
              #dc2418 12.4%,
              #841878 49.47%,
              #ba0c0c 85%);
          -webkit-text-fill-color: transparent;
          -webkit-background-clip: text;
        }
      }
    }

    .uiux_vector {
      position: absolute;
      bottom: 0px;
      right: 14rem;
    }

    .theme_btn {
      width: 199px;
      height: 55px;
      margin-left: auto;
    }
  }

  .rightSide {
    p {
      font-size: 18px;
      font-weight: 400;
      color: #cbcbcb;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem 0;
      padding-left: 20px;

      li {
        width: 50%;
        color: #cbcbcb;
        font-size: 18px;
        font-weight: 400;
      }
    }
  }

  .banner {
    margin-top: 5rem;
    display: flex;
    align-items: center;
    gap: 2rem;

    img {
      width: 100%;
    }

    .uiux_banner1 {
      top: -2rem;
      position: relative;
    }
  }
}

.hiringModels {
  padding: 45px 0;
  background-color: #202020;
  position: relative;

  // &::before {
  //   content: "";
  //   width: 100%;
  //   height: 100px;
  //   position: absolute;
  //   top: 0;
  //   background-color: #202020;
  // }
  // &::after {
  //   content: "";
  //   width: 100%;
  //   height: 100px;
  //   position: absolute;
  //   bottom: 0;
  //   background-color: #202020;
  // }
  .title {
    margin-bottom: 4rem;
    position: relative;

    .line_title {
      font-size: 24px;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 15px;
      color: $white;

      &::before {
        content: "";
        width: 37px;
        border: 2px solid $white;
        display: inline-block;
        border-radius: 50px;
      }
    }

    h1 {
      color: #fff;
      font-weight: 600;
      font-size: 46px;
      width: 100%;
      line-height: 84px;
      margin-bottom: 20px;

      span {
        font-size: 46px;
        font-weight: 700;
        background: $themeTextGradint;
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
      }
    }

    p {
      color: $secondaryText;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
    }
  }

  .modalBox {
    background-color: #111111;
    border-radius: 10px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 99;

    h4 {
      color: #fff;
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 20px;
    }

    p {
      color: #cbcbcb;
      margin-bottom: 30px;
    }

    .theme_btn {
      margin-left: auto;
      width: 199px;
      height: 55px;
    }
  }
}

.service_ecommerce {
  padding: 45px 0;

  .leftSide {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    position: relative;

    .titleName {
      display: flex;
      gap: 30px;

      .countNumber {
        font-size: 128px;
        font-weight: 700;
        color: #202020;
      }

      p {
        font-size: 46px;
        font-weight: 600;
        color: #fff;
        display: flex;
        flex-direction: column;
        margin: 0;
        justify-content: center;

        span {
          background: linear-gradient(92.05deg,
              #dc2418 12.4%,
              #841878 49.47%,
              #ba0c0c 85%);
          -webkit-text-fill-color: transparent;
          -webkit-background-clip: text;
        }
      }
    }

    .uiux_vector {
      position: absolute;
      bottom: 0px;
      right: 9rem;
    }

    .theme_btn {
      width: 199px;
      height: 55px;
    }
  }

  .rightSide {
    p {
      font-size: 18px;
      font-weight: 400;
      color: #cbcbcb;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem 0;
      padding-left: 20px;

      li {
        width: 50%;
        color: #cbcbcb;
        font-size: 18px;
        font-weight: 400;
      }
    }
  }

  .banner {
    margin-top: 5rem;
    display: flex;
    align-items: center;
    gap: 2rem;

    img {
      width: 100%;
    }

    .uiux_banner1 {
      top: -2rem;
      position: relative;
    }
  }
}

.service_qualityAssurance {
  padding: 45px 0;

  .leftSide {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    position: relative;

    .titleName {
      display: flex;
      gap: 30px;

      .countNumber {
        font-size: 128px;
        font-weight: 700;
        color: #202020;
      }

      p {
        font-size: 46px;
        font-weight: 600;
        color: #fff;
        display: flex;
        flex-direction: column;
        margin: 0;
        justify-content: center;

        span {
          background: linear-gradient(92.05deg,
              #dc2418 12.4%,
              #841878 49.47%,
              #ba0c0c 85%);
          -webkit-text-fill-color: transparent;
          -webkit-background-clip: text;
        }
      }
    }

    .uiux_vector {
      position: absolute;
      bottom: 0px;
      right: 14rem;
    }

    .theme_btn {
      width: 199px;
      height: 55px;
      margin-left: auto;
    }
  }

  .rightSide {
    p {
      font-size: 18px;
      font-weight: 400;
      color: #cbcbcb;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem 0;
      padding-left: 20px;

      li {
        width: 50%;
        color: #cbcbcb;
        font-size: 18px;
        font-weight: 400;
      }
    }
  }

  .banner {
    margin-top: 5rem;
    display: flex;
    align-items: center;
    gap: 2rem;

    img {
      width: 100%;
    }

    .uiux_banner1 {
      top: -2rem;
      position: relative;
    }
  }
}