@import "../../../App";
.navbar {
  .navbar-toggler {
    background-color: aliceblue;
  }
  .mobile_navigation {
    display: none;
  }
  .nav {
    display: flex;
    align-items: center;
    gap: 2rem;
    position: relative;
    &::after {
      background: linear-gradient(180deg, #dc2418 0%, #941a65 100%);
      content: "";
      width: 230px;
      height: 250px;
      position: absolute;
      top: -270px;
      filter: blur(110px);
      right: 35%;
    }
    li {
      position: relative;
    }

    a {
      color: #fffafa;
      position: relative;
      display: inline-block;
      letter-spacing: 1px;
      margin: 0;
      font-weight: 500;
      text-decoration: none;
      font-size: 16px;
      position: relative;
      text-transform: capitalize;
      // opacity: 0.8;
      &:hover,
      &.active {
        color: $themeRed;
        opacity: 1;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .navbar {
    .navbar-brand {
      margin: 0;
      padding: 0;
      img {
        width: 60%;
      }
    }
    .navbar-toggler {
      border-radius: 5px;
      padding: 0.3rem 0.5rem;
      .navbar-toggler-icon {
        width: 1.3em;
        height: 1.3em;
      }
    }
  }
}
