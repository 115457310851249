@import "../../../App";

.footer-section {
    position: relative;
    padding: 80px 0 0;

    .title {
        // margin-bottom: 4rem;

        .line_title {
            font-size: 24px;
            font-weight: 600;
            display: flex;
            align-items: center;
            gap: 15px;
            color: $white;

            &::before {
                content: "";
                width: 37px;
                border: 2px solid $white;
                display: inline-block;
                border-radius: 50px;
            }
        }

        h1 {
            color: #fff;
            font-weight: 600;
            font-size: 46px;
            width: 100%;
            line-height: 84px;
            margin-bottom: 20px;

            span {
                font-size: 46px;
                font-weight: 700;
                background: $themeTextGradint;
                -webkit-text-fill-color: transparent;
                -webkit-background-clip: text;
            }
        }

        p {
            color: $secondaryText;
            font-size: 18px;
            font-weight: 400;
            line-height: 27px;
        }
    }

    .footer-box {
        background: #282828;
        border-radius: 15px;
        padding: 10px 35px;

        .footer-title {
            display: flex;
            align-items: center;

            h3 {
                position: relative;
                font-size: 22px;
                font-weight: 600;
                line-height: 33px;
                color: #fff;
                margin-bottom: 0;

                &::before {
                    content: '';
                    position: absolute;
                    border: 2px solid #DC2418;
                    width: 40px;
                    height: 2px;
                    bottom: -10px;
                }
            }
        }

        .footer-link {
            padding: 20px 0 0;
            margin-bottom: 0;
            column-count: 3;

            li {
                padding-bottom: 20px;
                position: relative;

                a {
                    color: #fff;
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 400;

                    &::before {
                        content: '';
                        position: absolute;
                        background: #DC2418;
                        width: 8px;
                        height: 5px;
                        border-radius: 5px;
                        left: -15px;
                        top: 10px;
                    }

                    &:hover {
                        color: #DC2418;
                        transition: 0.4s;
                    }
                }

                &:last-child {
                    padding-bottom: 0;
                }
            }
        }

        .footer-social {
            margin-bottom: 0;
            padding: 32px 0 0;
            display: flex;
            align-items: center;
            gap: 25px;

            li {
                list-style-type: none;
            }
        }
    }

    .new-footer-links {
        // margin-top: 54px;

        .footer-title {
            display: flex;
            align-items: center;

            h3 {
                position: relative;
                font-size: 22px;
                font-weight: 600;
                line-height: 33px;
                color: #fff;
                margin-bottom: 0;

                &::before {
                    content: '';
                    position: absolute;
                    border: 2px solid #DC2418;
                    width: 40px;
                    height: 2px;
                    bottom: -10px;
                }
            }
        }

        .footer-link {
            padding: 30px 0 0;
            margin-bottom: 0;
            list-style: none;

            li {
                margin-bottom: 15px;
                position: relative;

                a {
                    color: #fff;
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 400;

                    &:hover {
                        color: #DC2418;
                        transition: 0.4s;
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .copyright-section {
        text-align: center;
        border-top: 1px solid #202020;
        padding: 32px 0;
        margin-top: 54px;

        p {
            color: #FFFFFF;
            margin: 0;
            font-size: 14px;
            font-weight: 400;
        }
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .footer-section {
        padding: 2rem 0 0;

        .title {
            margin-bottom: 2rem;

            h4 {
                font-size: 20px;
            }

            h1 {
                font-size: 26px;
                line-height: normal;

                span {
                    font-size: 26px;
                }
            }

            p {
                font-size: 17px;
                line-height: 30px;
            }
        }
        .new-footer-links{
            gap: 3rem;
        }
        .copyright-section{
            margin-top: 2rem;
        }
    }
}