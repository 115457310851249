// Default Colors

$maingreen: #1f1f1f;
$white: #ffffff;
$lightblack: #0d1116;
$black: #111111;
$themeRed: #dc2418;
$secondaryText: #cbcbcb;
$themeTextGradint: linear-gradient(92.05deg,
    #dc2418 12.4%,
    #841878 49.47%,
    #ba0c0c 85%);

.mt--5 {
  margin-top: -5rem;
}

.mt--10 {
  margin-top: -10rem;
}

.container {
  width: 1280px;
}

a {
  text-decoration: none;
  display: inline;
  color: $maingreen;
}

.theme_btn {
  background: linear-gradient(133.94deg,
      #dc2418 3.45%,
      #4e1848 49.43%,
      #740000 97.29%);
  position: relative;
  color: #ffffff !important;
  border: 0px solid #ffffff;
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    background-color: #111111;
    width: 99%;
    height: 97%;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 500ms;
  }

  &:hover {
    &::after {
      opacity: 0;
      transform: translate(-50%, -50%) rotateX(90deg);
    }
  }

  span {
    z-index: 99;
  }
}

.not_found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  h1 {
    color: $white;
    font-size: 50px;
  }
}

.react-multi-carousel-item {
  text-align: center;
}

.bt-0 {
  border-top: 0 !important;
}

.bb-0 {
  border-bottom: 0 !important;
}

.bl-0 {
  border-left: 0 !important;
}

.br-0 {
  border-right: 0 !important;
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.scroll-button {
  background-color: #fff;
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  padding: 5px;
  width: 50px;
  height: 50px;
}

@media (min-width: 1920px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1600px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .container {
    width: 100%;
  }

  p {
    font-size: 15px !important;
  }
}