@import "../../App";

.aboutpage {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 5rem 0 0;
  display: flex;
  align-items: center;

  .left {
    width: 85%;
    margin-left: auto;

    h1 {
      color: #fff;
      font-weight: 700;
      font-size: 56px;
      width: 100%;
      text-align: left;
      line-height: 84px;
      margin-bottom: 0px;
      position: relative;

      &::after {
        content: "";
        width: 1px;
        height: 100%;
        position: absolute;
        top: 0;
        left: -35px;
        background-color: #c9c9c9;
      }

      span {
        background: $themeTextGradint;
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
      }

      .sideSubTxt {
        position: absolute;
        transform: translate(-50%, -50%) rotate(-90deg);
        top: 50%;
        left: -75px;
        font-size: 26px;
        text-align: center;
        line-height: normal;
      }
    }

    p {
      color: $secondaryText;
      font-weight: 400;
      font-size: 20px;
      line-height: 40px;
    }
  }

  .right {
    position: relative;
    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }
  }

  .dot {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

.growWithUs {
  padding: 86px 0;
  position: relative;

  &:after {
    background: linear-gradient(180deg, #dc2418 0%, #941a65 100%);
    content: "";
    width: 200px;
    height: 200px;
    position: absolute;
    filter: blur(120px);
    top: 500px;
    left: -170px;
  }

  .title {
    margin-bottom: 4rem;

    .line_title {
      font-size: 24px;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 15px;
      color: $white;

      &::before {
        content: "";
        width: 37px;
        border: 2px solid $white;
        display: inline-block;
        border-radius: 50px;
      }
    }

    h1 {
      color: #fff;
      font-weight: 600;
      font-size: 46px;
      width: 100%;
      line-height: 84px;
      margin-bottom: 20px;

      span {
        font-size: 46px;
        font-weight: 700;
        background: $themeTextGradint;
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
      }
    }

    p {
      color: $secondaryText;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
    }
  }

  .mission-vision {
    .col-md-4 {
      padding: 0;
      border: 1px solid;

      &.top {
        position: relative;
        top: 85px;
      }

      &.customeTopPadding {
        .box {
          padding-top: 15px;
        }
      }

      &.customeBottomPadding {
        .box {
          padding-bottom: 15px;
        }
      }

      &.bigDot {
        .box {
          img {
            margin-top: 63px;
          }
        }
      }

      .box {
        padding: 40px;
        height: 100%;

        h1 {
          font-size: 68px;
          font-weight: 600;
          color: #fff;
        }

        h3 {
          font-size: 32px;
          font-weight: 500;
          color: #fff;
          margin-bottom: 15px;
        }

        p {
          color: $secondaryText;
          font-size: 18px;
          font-weight: 400;
        }
      }
    }
  }

  .growWithUsImg {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.counter {
  background-image: url(../../Assets/Red_Theme/backgroundDot.svg);
  padding: 60px 0;
  position: relative;

  &:after {
    background: linear-gradient(180deg, #dc2418 0%, #941a65 100%);
    content: "";
    width: 200px;
    height: 200px;
    position: absolute;
    filter: blur(120px);
    top: 0;
    right: -170px;
  }

  .box {
    h3 {
      font-size: 22px;
      font-weight: 600;
      color: #fff;
      margin-bottom: 15px;

      span {
        background: $themeTextGradint;
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
      }
    }

    p {
      color: $secondaryText;
      margin-bottom: 70px;
      position: relative;

      &::after {
        content: "";
        width: 100%;
        height: 2px;
        background: linear-gradient(
          91deg,
          #dc241894 20%,
          #dc241894 20%,
          #111111 60%
        );
        position: absolute;
        right: 0;
        bottom: -40px;
      }
    }

    h1 {
      font-size: 50px;
      font-weight: 600;
      color: #fff;
    }
  }
}

.approaches {
  padding: 107px 0;
  background-image: url(../../Assets/Red_Theme/approachesBackground.svg);
  background-repeat: no-repeat;
  background-position: center;
  // background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  min-height: 664px;

  .title {
    .line_title {
      font-size: 24px;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 15px;
      color: $white;

      &::before {
        content: "";
        width: 37px;
        border: 2px solid $white;
        display: inline-block;
        border-radius: 50px;
      }
    }

    h1 {
      color: #fff;
      font-weight: 600;
      font-size: 46px;
      width: 100%;
      line-height: 84px;
      margin-bottom: 20px;

      span {
        font-size: 46px;
        font-weight: 700;
        background: $themeTextGradint;
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
      }
    }

    p {
      color: $secondaryText;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
    }

    .dot {
      margin-top: 45px;
    }
  }

  .mainBox {
    display: flex;
    flex-wrap: wrap;
    gap: 40px 6%;

    .approachesBox {
      width: 47%;
      background: $themeTextGradint;
      padding: 1px;
      border-radius: 10px;
      overflow: hidden;
      height: 232px;
      z-index: 9;

      .blackBack {
        background-color: #202020;
        border-radius: 10px;
        padding: 19px;
        height: 100%;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          width: 199.38px;
          height: 230.32px;
          border-radius: 50%;
          background: linear-gradient(
            180deg,
            rgba(220, 36, 24, 0.4) 0%,
            rgba(148, 26, 101, 0.4) 100%
          );
          filter: blur(100px);
          top: -150px;
          left: 150px;
        }

        h1 {
          margin-bottom: 22px;
          font-size: 22px;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #fff;
        }

        p {
          margin: 0;
          font-size: 16px;
          font-weight: 400;
          color: $secondaryText;
        }
      }
    }
  }

  .dotOuter {
    position: absolute;
    right: 0;
    z-index: 99;
  }

  // &::after {
  //   content: "";
  //   position: absolute;
  //   background-color: #343434;
  //   width: 886px;
  //   height: 413px;
  //   right: 0;
  // }
}

.gallery {
  padding: 86px 0 0;
  position: relative;

  .title {
    margin-bottom: 4rem;

    .line_title {
      font-size: 24px;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 15px;
      color: $white;

      &::before {
        content: "";
        width: 37px;
        border: 2px solid $white;
        display: inline-block;
        border-radius: 50px;
      }
    }

    h1 {
      color: #fff;
      font-weight: 600;
      font-size: 46px;
      width: 100%;
      line-height: 84px;
      margin-bottom: 20px;

      span {
        font-size: 46px;
        font-weight: 700;
        background: $themeTextGradint;
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
      }
    }

    p {
      color: $secondaryText;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
    }
  }

  .carousel_card {
    position: relative;
    .galleryvector {
      top: -200px;
      position: absolute;
      right: 0;
    }
    .inner {
      position: relative;
      width: 100%;
      overflow: hidden;

      .wrapper {
        display: flex;
        gap: 20px;
      }

      .slide {
        display: flex;
        animation: swipe var(--speed) linear infinite backwards;
        gap: 20px;

        .image {
          width: 686px;
          height: 280px;
          object-fit: contain;
          border-radius: 10px;
          display: flex;
          gap: 20px;

          .left {
            width: 70%;

            .gall {
              width: 100%;
              height: 80%;
              border-radius: 10px;
              object-fit: cover;
            }

            h1 {
              height: 20%;
              margin: 0;
              color: #ffffff;
              font-size: 24px;
              font-weight: 600;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .theme_btn {
                padding: 0.5rem 1rem;
              }
            }
          }

          .right {
            width: 30%;
            display: flex;
            flex-direction: column;
            gap: 20px;
            align-items: center;
            justify-content: center;
            height: 100%;
            overflow: hidden;

            .gall {
              width: 100%;
              height: 46%;
              border-radius: 10px;
              object-fit: cover;
            }
          }
        }
      }
    }
  }

  .carousel_card_right {
    .inner {
      position: relative;
      width: 100%;
      overflow: hidden;

      .wrapper {
        display: flex;
        gap: 20px;
      }

      .slide {
        display: flex;
        animation: swipe1 var(--speed) linear infinite backwards;
        gap: 20px;

        .image {
          width: 686px;
          height: 280px;
          object-fit: contain;
          border-radius: 10px;
          display: flex;
          gap: 20px;

          .left {
            width: 70%;

            .gall {
              width: 100%;
              height: 80%;
              border-radius: 10px;
              object-fit: cover;
            }

            h1 {
              height: 20%;
              margin: 0;
              color: #ffffff;
              font-size: 24px;
              font-weight: 600;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .theme_btn {
                padding: 0.5rem 1rem;
              }
            }
          }

          .right {
            width: 30%;
            display: flex;
            flex-direction: column;
            gap: 20px;
            align-items: center;
            justify-content: center;
            height: 100%;
            overflow: hidden;

            .gall {
              width: 100%;
              height: 46%;
              border-radius: 10px;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}

@keyframes swipe {
  0% {
    transform: translate(0%);
  }

  100% {
    transform: translate(-100%);
  }
}

@keyframes swipe1 {
  0% {
    transform: translate(-100%);
  }

  100% {
    transform: translate(0%);
  }
}
